<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>PO Overview</h2>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="2">
        <v-autocomplete
          v-model="query.vendor"
          :items="vendors || []"
          item-text="name"
          item-value="id"
          label="ผู้ขาย"
          outlined
          clearable
          dense
          hide-details
          :search-input.sync="searchVendors">
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="query.sortBy"
          :items="sortByOptions"
          item-text="text"
          item-value="value"
          label="เรียงจาก"
          outlined
          dense
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="query.sortOrder"
          :items="sortOrderOptions"
          item-text="text"
          item-value="value"
          label="เรียงลำดับ"
          outlined
          dense
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getOverview(true)" />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="itemSelect"
          :headers="headers"
          :items="items"
          :items-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template #[`item.state`]="{ item }">
            {{ mapState(item.state) }}
          </template>
          <template #[`item.receivedText`]="{ item }">
            <span
              v-if="item.receivedQty === item.quantity"
              class="green--text">
              {{ item.receivedText }}
            </span>
            <span
              v-else-if="item.receivedQty && item.quantity"
              class="orange--text">
              {{ item.receivedText }}
            </span>
            <span
              v-else-if="!item.receivedQty"
              class="red--text">
              {{ item.receivedText }}
            </span>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/inventory/derivery-note-of-po/${item.orderNo}`">
              <v-icon small>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import VendorsProvider from '@/resources/VendorsProvider'

const PurchaseOrdersService = new PurchaseOrdersProvider()
const VendorsService = new VendorsProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'รหัส',
          value: 'orderNo',
          align: 'center'
        },
        {
          text: 'ผู้ขาย',
          value: 'vendor',
          sortable: false
        },
        {
          text: 'วันที่จัดส่ง',
          value: 'sendDate',
          align: 'center'
        },
        {
          text: 'สถานะ',
          value: 'state',
          align: 'center',
          width: '120'
        },
        {
          text: 'จำนวนที่รับ',
          value: 'receivedText',
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          align: 'center',
          sortable: false,
          width: '120'
        }
      ],
      itemSelect: [],
      items: [],
      sortByOptions: [
        {
          text: 'รหัส',
          value: 'orderNo'
        }
      ],
      sortOrderOptions: [
        {
          text: 'A-Z',
          value: 'asc'
        },
        {
          text: 'Z-A',
          value: 'desc'
        }
      ],
      query: {
        page: 1,
        limit: 10,
        search: '',
        sortBy: 'orderNo',
        sortOrder: 'desc',
        vendor: ''
      },
      pageCount: 0,
      loading: false,
      vendors: [],
      searchVendors: '',
      delayVendorSearch: null
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getOverview()
      },
      deep: true
    },
    'query.sortBy': {
      handler () {
        this.getOverview(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getOverview(true)
      },
      deep: true
    },
    'query.vendor': {
      handler () {
        this.getOverview(true)
      },
      deep: true
    },
    'searchVendors': {
      handler () {
        clearTimeout(this.delayVendorSearch)
        this.delayVendorSearch = setTimeout(() => {
          this.getVendorsItems()
        }, 300)
      },
      deep: true
    }
  },
  mounted () {
    this.getOverview(true)
    this.getVendorsItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getOverview (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PurchaseOrdersService.getOverview({
          ...this.query
        })
        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getVendorsItems () {
      try {
        this.loading = true
        const { data } = await VendorsService.getItems({
          page: 1,
          limit: 20,
          search: this.searchVendors
        })
        this.vendors = data.results
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapState (state) {
      switch (state) {
        case 'pending': return 'รอการอนุมัติ'
        case 'approved': return 'อนุมัติแล้ว'
        case 'received': return 'รับสินค้าแล้ว'
        case 'canceled': return 'ยกเลิก'
        default: return state
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
